import * as React from "react"
import styled from "styled-components"
import { up } from "styled-breakpoints"

import { space, color } from "../../theme"

import Text from "../../components/text"
import { Link } from "../../components/link"
import Img from "../../components/img"
import {
  LeftSymbolAbout,
  RightSymbolAbout,
  AboutMobile,
} from "../../components/synbols"

export type Block1Props = {
  title: string
  subtitle: string
  copy: string
  img: ImageType
  link: LinkType
}
export type Props = {
  data: Block1Props
  options?: {}
}

const SuperContent = styled.div`
  position: relative;
  display: grid;
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  padding: 0;
  padding-top: ${space("large")};
  margin: 0 auto;
  ${up("tablet")} {
    grid-template-columns: 704px;
  }
  ${up("desktop")} {
    grid-template-columns: 1216px;
    padding: ${space("large")} ${space("normal")};
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(3, auto);
  grid-gap: ${space("normal")};
`
const Content = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(3, auto);
  grid-gap: ${space("normal")};
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
  padding: 0 ${space("normal")};
  padding-bottom: ${space("normal")};
  ${up("desktop")} {
    grid-template-columns: 612px;
  }
`
const Spacer = styled.div`
  width: 90%;
  margin: 0 auto;
  height: 1px;
  background-color: ${color("lightgrey")};
  ${up("desktop")} {
    width: 100%;
  }
`

const BG = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 0;
  background-color: ${color("yellow")};
  z-index: -1;
  ${up("tablet")} {
  }
  ${up("desktop")} {
    height: 672px;
  }
`
const Green = styled(LeftSymbolAbout)`
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  ${up("desktop")} {
    display: block;
  }
`
const Red = styled(RightSymbolAbout)`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  ${up("desktop")} {
    display: block;
  }
`
const Rose = styled(AboutMobile)`
  display: block;
  position: absolute;
  bottom: -50px;
  left: 0;
  ${up("desktop")} {
    display: none;
  }
`

const Title = styled(Text)`
  text-align: center;
  ${up("desktop")} {
    text-align: left;
  }
`

const Block1: React.FC<Props> = ({ data }) => {
  return (
    <SuperContent>
      <BG>
        <Green />
        <Red />
        <Rose />
      </BG>
      <Wrapper>
        <Container>
          <Title type="headline">{data.title}</Title>
          <Spacer />
          {/* <Img
            // src={{
            //   default: data.img.src,
            //   mobile: `${data.img.src}?crop=focalpoint&fp-x=.5&fp-y=.4&fit=crop&h=250&w=704`,
            //   tablet: `${data.img.src}?crop=focalpoint&fp-x=.5&fp-y=.4&fit=crop&h=450&w=704`,
            //   desktop: `${data.img.src}?crop=focalpoint&fp-x=.5&fp-y=.4&fit=crop&h=537&w=1196`,
            // }}
            src="/temp.jpg"
            alt={data.img.alt}
            mw={"100%"}
            mh={"225px"}
            tw={"100%"}
            th={"450px"}
            dw={"1196px"}
            dh={"537px"}
            fit="cover"
          /> */}
        </Container>
      </Wrapper>
      <Wrapper>
        <Content>
          <Text align="center" type="headline">
            {data.subtitle}
          </Text>
          <Text align="center">{data.copy}</Text>
          <Link to={data.link.to} bg="white">
            {data.link.copy}
          </Link>
        </Content>
      </Wrapper>
    </SuperContent>
  )
}

export default Block1
