import React from "react"
import { Helmet } from "react-helmet"

import AboutUs, { AboutProps } from "../blocks/About"

const AboutUsPage = () => (
  <>
    <Helmet>
      <title>Paybase - About us</title>
      <meta property="og:title" content="Paybase - About us" />
    </Helmet>
    <AboutUs
      data={
        {
          block1: {
            title: "About us",
            subtitle:
              "Paybase empowers businesses to design the economies of tomorrow_",
            copy:
              "Too many businesses are being failed by payments, often being told that their innovative ideas are unfeasible, too expensive or simply not worth the payment providers' time. Paybase takes a different approach. Our flexibility puts our clients in the driving seat, allowing them to build the product they want to build and to enhance it as they develop. This approach is not only critical to helping businesses reach market but it furthermore enables them to continuously improve their offering. At Paybase, we empower businesses to design the economies of tomorrow -  and do so without compromise.",
            link: { to: "/how-we-do-it", copy: "Find out how we do it" },
            img: {
              src: "https://paybase.imgix.net/assets/team_paybase.jpg",
              alt: "placeholder",
            },
          },
        } as AboutProps
      }
    />
  </>
)

export default AboutUsPage
