import * as React from "react"
import styled from "styled-components"

import Layout from "../../components/layout"

import Block1, { Block1Props } from "./intro"

export type AboutProps = {
  block1: Block1Props
}
export type Props = {
  data: AboutProps
  options?: {}
}

const Container = styled.div`
  display: grid;
`

const About: React.FC<Props> = ({ data, options }) => {
  return (
    <Layout>
      <Container>
        <Block1 data={data.block1} />
      </Container>
    </Layout>
  )
}

export default About
